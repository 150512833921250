import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { PageHeader } from '../../components/PageHeader'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export default class ContactPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/?no-cache=1', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state
            })
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }

    render() {
        return (
            <Layout>
                <PageHeader image="/img/contact-header.jpg" title="Contact" />
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <h1>E-mail</h1>
                            <p>
                                Feel free to contact us by sending an e-mail to{' '}
                                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#113;&#98;&#112;&#45;&#115;&#105;&#109;&#117;&#108;&#97;&#116;&#111;&#114;&#46;&#99;&#111;&#109;">
                                    &#105;&#110;&#102;&#111;&#64;&#113;&#98;&#112;&#45;&#115;&#105;&#109;&#117;&#108;&#97;&#116;&#111;&#114;&#46;&#99;&#111;&#109;
                                </a>
                                .
                            </p>
                            <p>Whether you need support or are interesting in partnership - we are looking forward to hearing from you!</p>
                        </div>
                        <div className="content">
                            <h4>Company Information</h4>
                            <p>Name: MABEL OÜ (registry code: 14171840)</p>
                            <p>Address: Sõlme 6, Tallinn 11612, Estonia</p>
                            <p>Phone: +1 910 338 0596</p>
                        </div>
                        <div className="content">
                            <h1>Contact Form</h1>
                            <p>Alternatively, use the form below to send us a message.</p>
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="please-fill"
                                onSubmit={this.handleSubmit}
                            >
                                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                <input type="hidden" name="form-name" value="contact" />
                                <div className="is-hidden">
                                    <label>
                                        <input name="please-fill" onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'name'}>
                                        Your name
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={'text'}
                                            name={'name'}
                                            onChange={this.handleChange}
                                            id={'name'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'email'}>
                                        Email
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={'email'}
                                            name={'email'}
                                            onChange={this.handleChange}
                                            id={'email'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={'message'}>
                                        Message
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={'message'}
                                            onChange={this.handleChange}
                                            id={'message'}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-link" type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
